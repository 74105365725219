import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Landing from "./Landing";

function App() {
    return (
        <div id="App">
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Landing></Landing>}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
