import React from "react";
import { Link } from "react-router-dom";
import { FiMail, FiInstagram } from "react-icons/fi";
import { FaFilePdf } from "react-icons/fa";
import { SiLinktree } from "react-icons/si";
import { BsFileEarmarkPdf } from "react-icons/bs";

function Landing() {
    return (
        <div id="landing">
            <p style={{ fontSize: "55px", fontWeight: "bold", margin: "10px" }}>
                Bolte works
            </p>
            <div id="landingLinks">
                <Link
                    to="#"
                    className="link"
                    onClick={(e) => {
                        window.location = "mailto:bolteworks@protonmail.com";
                        e.preventDefault();
                    }}
                >
                    <FiMail size={30}></FiMail>
                </Link>
                <a
                    href="https://instagram.com/blacklightultra"
                    className="link"
                    target="_blank"
                >
                    <FiInstagram size={30}></FiInstagram>
                </a>
                <a
                    href="https://wqkthukvoronkpcdinfu.supabase.in/storage/v1/object/sign/iuno/portfolio_bolteworks.pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpdW5vL3BvcnRmb2xpb19ib2x0ZXdvcmtzLnBkZiIsImlhdCI6MTY0NDg0NzkyMywiZXhwIjoxOTYwMjA3OTIzfQ.4IDehtPJdZMtANRaEbwVaVVy3uqDyM1iEH1nb-F8l_A"
                    className="link"
                    target="_blank"
                >
                    <BsFileEarmarkPdf size={30}></BsFileEarmarkPdf>
                </a>
                <a
                    href="https://linktr.ee/bolteworks
"
                    className="link"
                    target="_blank"
                >
                    <SiLinktree size={30} />
                </a>
            </div>
        </div>
    );
}

export default Landing;
